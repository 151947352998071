import React from "react"

const History = ({ title, description, timeSpan }) => {
  return (
    <div className="history-entry">
      <span className="dot-double"></span>
      <h4>{title}</h4>
      <h5 className="text-sm font-normal mb-1">{description}</h5>
      <span className="text-sm font-medium opacity-50">{timeSpan}</span>
    </div>
  )
}

export default History
