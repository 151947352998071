import React, { useState,Component } from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import LogoThick from "../../../assets/SR_Logo_Thick.png"
import Typist from 'react-typist';

const Header = () => {
  const isMobile = useBreakpoint();
  const [bar, setBar] = useState(false);
  let fields = [];
  for (let i = 0; i <= 20; i++) {
    fields.push(<span className="typed-text">researcher.</span>);
    fields.push(<span className="TypeCursor">&nbsp;</span>);
    fields.push(<Typist.Backspace count={12} delay={3000} />);
    fields.push(<span className="typed-text">developer.</span>);
    fields.push(<span className="TypeCursor">&nbsp;</span>);
    fields.push(<Typist.Backspace count={12} delay={3000} />);
    fields.push(<span className="typed-text">hobbyist.</span>);
    fields.push(<span className="TypeCursor">&nbsp;</span>);
    fields.push(<Typist.Backspace count={12} delay={3000} />);
  }
  return (
    <section id="imgback" class="intro_image">
      <div id="filter">
        <header id="home-head">
          {isMobile.sm ? (
            <div className="topnav mobile">
              <a href="/" className="active">
                <img id="mobile-icon" src={LogoThick} />
              </a>
              {bar ? (
                <div id="myLinks">
                  <a href="http://suhasraja.xyz/resume.pdf" download className="button">
                    Resume
                  </a>
                  <a href="mailto:me@suhasraja.xyz" className="button" styles={{align:"right"}}>
                    Contact Me
                  </a>
                </div>
              ) : <div/>}
              <a href="/#" onClick={() => setBar(!bar)} className="icon">
                <i className="fa fa-bars fa-2x"></i>
              </a>
            </div>
          ) : (
            <div id="navbar" className="desktop">
              <div id="icon-div">
                <a href="/" id="icon-wrapper">
                  <img id="logo" src={LogoThick} alt="Suhas Raja" />
                </a>
              </div>
              <div id="nav-div">
                <a href="/resume.pdf" className="button">
                  Resume
                </a>
                <a href="https://github.com/Suhas7" className="button">
                  Github
                </a>
                <a href="https://forms.gle/m7yL8o8WW5xaea6v5" className="button" style={{align:"right"}}>
                  Contact Me
                </a>
              </div>
            </div>
          )}
          <div className="container statement typing">
            <h1>
              <Typist cursor={{show:false}} startDelay={300} >
                suhas raja.
              </Typist>
            </h1>
            <h1 id="typing-animation">
              <Typist cursor={{show:false}} startDelay={4000} >
                {fields}
              </Typist>
            </h1>
          </div>
        </header>
      </div>
    </section>
  )
}

export default Header
